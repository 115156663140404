<script setup lang="ts">
import type { SubjectCode } from '~/models/Subject'
import type { Group } from '~/models/Group'
import type { GradeCode } from '~/models/Grade'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsDropdown } from '@aschehoug/kloss'
import { waitFor } from '~/utils/asyncUtils'
import useGroupsStore from '~/stores/groups'
import { PendoFeature } from '~/models/Pendo'

const { t } = useI18n()
const groupsStore = useGroupsStore()
const { findGroupById } = groupsStore
const { sortedGroups, hasGroups, isLoading: isLoadingGroups } = storeToRefs(groupsStore)

const filterModel = defineModel<{
  groupIdValue?: string
  gradeValue?: GradeCode
  subjectValue: SubjectCode
  afterValue: string
  beforeValue: string
  contextValue: string
}>()

const groupValue =  ref<Group>()
const gradeValue = ref<GradeCode>()
const subjectValue = ref<SubjectCode>()
const afterValue = ref<string>()
const beforeValue = ref<string>()
const contextValue = ref<string>()
const gradeOptions = ref(['aarstrinn8', 'aarstrinn9', 'aarstrinn10'])

const reportFilter = computed(() => {
  return {
    groupValue: groupValue.value?.groupId,
    gradeValue: gradeValue.value,
    subjectValue: subjectValue.value,
    afterValue: afterValue.value,
    beforeValue: beforeValue.value,
    contextValue: contextValue.value
  }
})

watch(reportFilter, () => {
  if (filterModel.value.groupIdValue !== reportFilter.value.groupValue) filterModel.value.groupIdValue = reportFilter.value.groupValue
  if (filterModel.value.gradeValue !== reportFilter.value.gradeValue) filterModel.value.gradeValue = reportFilter.value.gradeValue
}, { deep: true })

watch(filterModel, async () => {
  if (filterModel.value.groupIdValue !== groupValue.value?.groupId) {
    // Wait for groups if this is initial page load
    if (!hasGroups.value && isLoadingGroups.value) {
      await waitFor(() => isLoadingGroups.value === false, 2500)
    }

    groupValue.value = findGroupById(filterModel.value.groupIdValue)
  }

  if (filterModel.value.gradeValue !== gradeValue.value) gradeValue.value = filterModel.value.gradeValue
}, { deep: true, immediate: true })

</script>

<template>
  <div class="my-6 flex flex-row gap-y-6 space-x-5">
    <label class="space-y-2">
      <span
        class="text-base font-medium"
        v-text="t('report.toolbar.groupDropdown')"
      />
      <KsDropdown
        v-model="groupValue"
        :options="sortedGroups"
        :placeholder="t('report.toolbar.groupDropdown')"
        :option-id="(group: Group) => group.groupId"
        :option-label="(group: Group) => group.name"
        :option-value="(group: Group) => group"
        :flexible="true"
        class="z-20 w-full"
        :data-pendo="PendoFeature.Report.FilterGroup"
      />
    </label>
    <label class="space-y-2">
      <span
        class="text-base font-medium"
        v-text="t('report.toolbar.gradeDropdown')"
      />
      <KsDropdown
        v-model="gradeValue"
        :options="gradeOptions"
        :placeholder="t('report.toolbar.gradeDropdown')"
        :option-id="(grade: GradeCode) => grade"
        :option-label="(grade: GradeCode) => t(`metadata.grades.${grade}`)"
        :option-value="(grade: GradeCode) => grade"
        :flexible="true"
        class="w-full"
        :data-pendo="PendoFeature.Report.FilterGrade"
      />
    </label>
  </div>
</template>
